import React, { useState } from 'react';

export default function Kalkulator() {
  const [workload, setWorkload] = useState(3);
  const [officeDays, setOfficeDays] = useState(0);
  const [equipmentByUs, setEquipmentByUs] = useState(false);

  const workloadOptions = [
    { label: '1/4 etatu', value: 2500 },
    { label: '1/2 etatu', value: 5000 },
    { label: '3/4 etatu', value: 7500 },
    { label: '1 etat', value: 10000 },
    { label: '2 etaty', value: 20000 }
  ];

  const officeDaysOptions = [
    { label: 'zdalnie', value: 0 },
    { label: '1 dzień', value: 1000 },
    { label: '2 dni', value: 2000 },
    { label: '3 dni', value: 3000 }
  ];

  const calculateTotal = () => {
    return workloadOptions[workload].value + 
           officeDaysOptions[officeDays].value + 
           (equipmentByUs ? 500 : 0);
  };

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-2">
          <label className="font-medium text-lg text-custom-blue">
            Ilu pracowników musiałbyś zatrudnić do wykonywania tych zadań?
          </label>
          <div className="relative h-6 mb-2">
            <div className="absolute w-full flex justify-between">
              {workloadOptions.map((option, index) => (
                <button 
                  key={index} 
                  onClick={() => setWorkload(index)}
                  className={`text-sm whitespace-nowrap cursor-pointer hover:opacity-80 ${workload === index ? 'font-bold text-black' : 'text-gray-400'}`}
                  style={{
                    position: 'absolute',
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    left: `${index === 0 ? '0%' : index === workloadOptions.length - 1 ? '100%' : (index / (workloadOptions.length - 1)) * 100 + '%'}`,
                    transform: index === 0 ? 'translateX(0)' : index === workloadOptions.length - 1 ? 'translateX(-100%)' : 'translateX(-50%)'
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>
          <input
            type="range"
            value={workload}
            min={0}
            max={4}
            step={1}
            onChange={(e) => setWorkload(Number(e.target.value))}
            className="w-full"
          />
        </div>

        <div className="space-y-2">
          <label className="font-medium text-lg text-custom-blue">
            Ile dni w tygodniu nasz Specjalista będzie pracował w Twoim biurze?
          </label>
          <div className="relative h-6 mb-2">
            <div className="absolute w-full flex justify-between">
              {officeDaysOptions.map((option, index) => (
                <button 
                  key={index} 
                  onClick={() => setOfficeDays(index)}
                  className={`text-sm whitespace-nowrap cursor-pointer hover:opacity-80 ${officeDays === index ? 'font-bold text-black' : 'text-gray-400'}`}
                  style={{
                    position: 'absolute',
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    left: `${index === 0 ? '0%' : index === officeDaysOptions.length - 1 ? '100%' : (index / (officeDaysOptions.length - 1)) * 100 + '%'}`,
                    transform: index === 0 ? 'translateX(0)' : index === officeDaysOptions.length - 1 ? 'translateX(-100%)' : 'translateX(-50%)'
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>
          <input
            type="range"
            value={officeDays}
            min={0}
            max={3}
            step={1}
            onChange={(e) => setOfficeDays(Number(e.target.value))}
            className="w-full"
          />
        </div>

        <div className="flex items-center justify-between">
          <label className="font-medium text-lg text-custom-blue">
            Laptop, telefon i licencję Microsoft 365 zapewnia Wsparcie Biura
          </label>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setEquipmentByUs(!equipmentByUs)}
              className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${equipmentByUs ? 'bg-blue-500' : 'bg-gray-300'}`}
            >
              <div
                className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${equipmentByUs ? 'translate-x-6' : ''}`}
              />
            </button>
            <span className="font-medium text-custom-blue" style={{width: '30px'}}>{equipmentByUs ? 'TAK' : 'NIE'}</span>
          </div>
        </div>

        <div className="text-center p-4">
          <p className="font-semibold text-lg text-orange-500">
            Miesięczna opłata: {calculateTotal().toLocaleString()} PLN netto
          </p>
        </div>
      </div>
    </>
  );
}
