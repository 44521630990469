import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    nip: '',
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    nip: '',
    name: '',
    email: '',
    phone: ''
  });

  const [submitStatus, setSubmitStatus] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const validateNIP = (nip: string) => {
    return /^[0-9]{10}$/.test(nip);
  };

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleBlur = (field: string) => {
    if (field === 'nip' && formData.nip && !validateNIP(formData.nip)) {
      setErrors(prevErrors => ({ ...prevErrors, nip: 'Niepoprawny format NIPu' }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, nip: '' }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let valid = true;
    let newErrors = { nip: '', name: '', email: '', phone: '' };

    if (!formData.name) {
      newErrors.name = 'Imię i nazwisko jest wymagane';
      valid = false;
    }

    if (!formData.email && !formData.phone) {
      newErrors.email = 'Adres e-mail lub numer telefonu jest wymagany';
      newErrors.phone = 'Adres e-mail lub numer telefonu jest wymagany';
      valid = false;
    } else if (formData.email && !validateEmail(formData.email)) {
      newErrors.email = 'Niepoprawny format adresu e-mail';
      valid = false;
    }

    if (formData.nip && !validateNIP(formData.nip)) {
      newErrors.nip = 'Niepoprawny format NIPu';
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      const apiUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '0.0.0.0'
        ? 'http://127.0.0.1:8000/contact-form'
        : 'https://wsparciebiura.pl:8000/contact-form';

      try {
        const response = await fetch(`${apiUrl}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ request: formData })
        });

        if (response.ok) {
          setSubmitStatus('Formularz wysłany pomyślnie');
          setSubmitError(null); // Reset błędu
        } else {
          const errorData = await response.json();
          setSubmitError(errorData.message || 'Nie udało się wysłać formularza');
          setSubmitStatus(null); // Reset statusu
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          setSubmitError(`Błąd sieci: ${error.message}`);
        } else {
          setSubmitError('Wystąpił nieznany błąd');
        }
        setSubmitStatus(null); // Reset statusu
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  return (
    <div>
      {submitStatus ? (
        <div className="w-full bg-green-100 text-green-700 py-3 px-6 rounded-lg text-lg">
          {submitStatus}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="nip" className="block text-gray-700 font-bold mb-2">NIP firmy</label>
            <input
              type="text"
              id="nip"
              name="nip"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-custom-blue"
              value={formData.nip}
              onChange={handleChange}
              onBlur={() => handleBlur('nip')}
            />
            {errors.nip && <p className="text-red-500 text-xs italic">{errors.nip}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
              Imię i nazwisko (osoba kontaktowa) <span className="text-orange-500">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-custom-blue"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
              Firmowy adres e-mail <span className="text-orange-500">**</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-custom-blue"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">
              Numer telefonu <span className="text-orange-500">**</span>
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-custom-blue"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-gray-700 font-bold mb-2">Temat konsultacji</label>
            <textarea
              id="message"
              name="message"
              style={{ height: "100px" }}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-custom-blue"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <p className="text-gray-600 text-sm mb-4">
            Wysyłając formularz wyrażasz zgodę na kontakt telefoniczny lub mailowy w celu obsługi tego zgłoszenia oraz wyrażasz zgodę na komunikację e-mail w celach marketingowych.
          </p>
          <button
            type="submit"
            className="w-full bg-custom-blue text-white py-3 px-6 rounded-lg text-lg hover:bg-blue-400 hover:shadow-lg transition duration-300"
          >
            Wyślij zapytanie
          </button>
          {submitError && (
            <p className="text-red-500 text-xs italic mt-2">{submitError}</p>
          )}
        </form>
      )}
    </div>
  );
}

export default ContactForm;